import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthGuard } from '../auth/auth.guard';

interface ItemsResponseObj { }
interface ItemsResponse { }

@Injectable({
  providedIn: 'root'
})

export class AgentdashboardService {

  loginusername;

  constructor(private httpClient: HttpClient) { }

  recentactivities_data(): Observable<ItemsResponse> {
	this.loginusername = AuthGuard.loginusername ? AuthGuard.loginusername : 'myousuff';
    // this.loginusername = AuthGuard.loginusername;
    var date = new Date();
    var today = date.toISOString().split('T')[0];
    date.setDate(date.getDate() - 30);
    var last30days = date.toISOString().split('T')[0]; // "2016-06-08"
     var url = environment.apiUrl+'alliancectr/recent-activity?username=' + this.loginusername + '&startDate=' + last30days + '&endDate=' + today + '&pageNo=0&pageSize=10000&sortBy=connectedToAgentTimestamp';
  
    return this.httpClient.get<ItemsResponse>(url);
  }

  agentmetrics_data(): Observable<ItemsResponse> {
    this.loginusername = AuthGuard.loginusername;
    var date = new Date();
    var today = date.toISOString().split('T')[0];
    date.setDate(date.getDate() - 30);
    var last30days = date.toISOString().split('T')[0]; // "2016-06-08"
    // var url = environment.apiUrl+'alliancectr/agent-metrics?username=' + this.loginusername + '&startDate=' + last30days + '&endDate=' + today + '';
    var url = environment.apiUrl+'alliancectr/agent-metrics?username=' + this.loginusername + '&startDate=' + last30days + '&endDate=' + today + '';
  
    return this.httpClient.get<ItemsResponse>(url);
  }

}

